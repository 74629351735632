import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Policy from '../views/PrivacyPolicy/Policy.vue'
// import Register from '../views/Users/Register.vue'
// import Login from '../views/Users/Login.vue'
// import Profile from '../views/Users/Profile.vue'
// import GaleryList from '../views/Galery/List.vue'
// import Contact from '../views/Contact.vue'
// import Success from '../views/Success.vue'
// import About from '../views/About.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/Index.vue'),
    children:[
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/qr/card',
        name: 'Home',
        component: Home
      },
      // {
      //   path: '/policy',
      //   name: 'Policy',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: Policy
      // },
      // {
      //   path: '/user/register',
      //   name: 'UserRegister',
      //   component: Register
      // },
      // {
      //   path: '/about',
      //   name: 'About',
      //   component: About
      // },
      // {
      //   path: '/user/login',
      //   name: 'UserLogin',
      //   component: Login
      // },
      // {
      //   path: '/user/profile',
      //   name: 'UserProfile',
      //   component: Profile
      // },
      // {
      //   path: '/galery',
      //   name: 'Galery',
      //   component: GaleryList
      // },
      // {
      //   path: '/contact',
      //   name: 'Contact',
      //   component: Contact
      // },
      // {
      //   path: '/success',
      //   name: 'Success',
      //   component: Success
      // },
      
    ]
  },
  {
    path: '/payments/card',
    name: 'PaymentCard',
    component: () => import('@/views/Payments/Cards.vue'),
  },
  {
    path: '/promo',
    name: 'Promo',
    component: () => import('@/views/Promos/Promo.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
