<template>
  <div class="">
    <!-- <v-carousel
      cycle
      :height="appHeight"
      hide-delimiter-background
      interval="15000"
      :show-arrows="false"
      show-arrows-on-hover
    >
      <v-carousel-item
        src="@/assets/images/design-011.jpg"
      >
      <v-sheet              
        :height="appHeight"
        style="background:#00000090"
      > 
      <v-container grid-list-xs>
        <div style="position:absolute;width:100%" :style="`${(!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm)?'bottom:100px ':'bottom:20px'}`">
          <v-row >
            <v-col cols="12" md="6" >
              
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-center white--text font-weight-bold" :class="`${$vuetify.breakpoint.mobile?'text-h4':'text-h1'}`">
                <div class="primary--text  font-weight-bold">
                  TINTES
                </div>
                PARA TODO
                <div class="primary--text font-weight-bold ">
                  TIPO DE
                </div>
                CABELLO
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              
              <div style="max-width:500px" class="text-center " :class="`${$vuetify.breakpoint.mobile?' text-body-2 ':' text-h5 '}`">
                <div class="primary--text mb-4 text-center" style="text-shadow: 0.03em 0.03em #cccccc50" :class="`${$vuetify.breakpoint.mobile?' text-h5 ':' text-h4 '}`">
                  <span  class="font-weight-bold text-uppercase" >
                    La brujita
                  </span>Solón de belleza.
                </div>
                Mas de 6 años, <br>
               <span class="font-weight-bold">embelleciendo</span>  a nuestros <br>
                clientes. <p></p>

                Ofrecemos servicios como: <br>
                uñas, manicura, depilación, <br>
                cortes de cabello, coloración, <br>
                peinados
              </div>
            </v-col>
            <v-col cols="12" md="6">
              
            </v-col>
            
          </v-row>
        </div>
                
      </v-container>
      </v-sheet>
      </v-carousel-item>
      <v-carousel-item
        src="@/assets/images/design-012.jpg"
      >
      <v-sheet              
          :height="appHeight"
          style="background:#00000090"
        > 
          <v-container grid-list-xs>
            <div style="margin-top:150px">
              <div class="font-weight-bold primary--text" :class="`${$vuetify.breakpoint.mobile?'text-h2 text-center':'text-h1'}`">
                TU ESCAPE
              </div>
              <div class="text-h3" :class="`${$vuetify.breakpoint.mobile?'text-h4 text-center':'text-h3'}`">
                DE LA RUTINA DIARIA
              </div>
            </div>
          </v-container>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <div style="height:800px;background:#fff" >
      <v-container grid-list-xs>
        <v-row style="height:800px;width:100%" align="center" justify="center">
          <v-col cols="12"  sm="8" md="8" >
            <v-row align="center" justify="center">
              <v-col cols="12" md="6" class="pa-0">
                <div style="position:relative;height:500px;padding:2px;overflow:hidden">
                  <div style="left:100px;top:100px;position:absolute;height:300px;width:200px;border:10px solid #fbba24" >
                    
                  </div>
                  <div style="left:150px;top:250px;position:absolute;height:240px;width:270px;border:10px solid white;overflow:hidden" class="elevation-2">
                    <img src="designs/girasol.jpg" alt="" style="position:absolute;width:250px;top:-70px">
                  </div>
                  <div style="position:absolute;height:350px;border:10px solid white;overflow-y:none" class="elevation-2">
                    <img src="designs/IMG_20190603_143323.jpg" alt="" style="height:330px;">
                  </div>
                  
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="text-center" style="font-size:40px">
                  
                      Haz tiempo para ti, es verdaderamente mágico
                </div>
                <div style="text-align: justify;text-justify: inter-word">
                  Cuando sienta que su rutina de trabajo diario realmente la agotó, no dude en venir. ¡Nuestras manicuristas profesionales harán todo lo posible para calmar su cuerpo y mente! Elija cualquier color de esmalte de uñas y lo haremos funcionar para usted. !
                </div>
                
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      
    </div> -->
  </div>
</template>

<script>

import {mapState} from 'vuex'
export default {
  name: 'Home',
  components: {

  },
  computed:{
    ...mapState(['appHeight'])
  }
}
</script>
