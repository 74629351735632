import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:null,
    appHeight:'100px',
    cookiesAccepted:false,
    loading:false
  },
  mutations: {
    setCookiesAccepted(state,value){
      state.cookiesAccepted=value;
    },
    userSet(state,user){
      state.user=user;
    },
    setHeight(state,arg){
      state.appHeight=arg;
    },
    setLoading(state,value){
      state.loading=value;
    }
  },
  actions: {
  },
  modules: {
  }
})
