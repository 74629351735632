<template>
  <v-app class="app" >
    
    <FirstPage v-if="!cookiesAccepted"></FirstPage >
    <Loading :active="loading"/>
    <!-- start cursor -->
        
    <div class="cursor" id="cursor"></div>
    <div class="social " v-if="!$vuetify.breakpoint.mobile">
      <div class="text-center">
        <v-btn color="#8000FF" text icon href="https://www.facebook.com/labrujitabelleza" target="blank">
          <v-icon color="#8000FF" small>fab fa-facebook-f</v-icon>          
        </v-btn>        
      </div>
      <div class="text-center">
        <v-btn color="#8000FF" text icon href="https://goo.gl/maps/VqpA8v2FLENagDAG9" target="blank">
          <v-icon color="#8000FF" small>fas fa-map-marker</v-icon>         
        </v-btn>        
      </div>
      <div class="text-center">
        <v-btn color="#8000FF" text icon href="https://wa.link/i7ip97" target="blank">
          <v-icon color="#8000FF" small>fab fa-whatsapp</v-icon>         
        </v-btn>        
      </div>
      <div class="text-center" >
        <v-divider style="height:50px" color="#8000FF" vertical></v-divider>
        <div class="text-rotate text-center text-caption" >          
          Contactanos
        </div>
      </div>
    </div>
    
    <!-- End  Cursor -->
    <!-- <v-app-bar
      app
      
      dense
      dark
      flat
      fixed
      style="background:#191A1Adf"
    >
      
    </v-app-bar> -->
    <main style="background:#191A1C;color:white;" v-if="cookiesAccepted">     
        <router-view >          
        </router-view>
    </main>
    <v-footer color="#1D1E20" dark>
      <v-container grid-list-xs>
        <v-row justify="center" align="center">
          <v-col cols="11" sm="11" md="10" lg="8">
            <v-row>
              <v-col cols="12" md="6">
                © LaBrujita 2022. Todos los derechos reservados.
              </v-col>
              <v-col cols="12" md="6">
                <v-btn small color="white" class="mx-1" href="https://www.facebook.com/labrujitabelleza" elevation="0" outlined>
                  <v-icon small>fab fa-facebook-f</v-icon>
                </v-btn>
                <v-btn small color="white" class="mx-1" href="https://wa.link/i7ip97" elevation="0" outlined>
                  <v-icon small>fab fa-whatsapp</v-icon>
                </v-btn>
                <v-btn small color="white" class="mx-1" href="https://goo.gl/maps/VqpA8v2FLENagDAG9" elevation="0" outlined>
                  <v-icon small>fas fa-map-marker</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <div style="position:fixed;bottom:10px;right:20px;z-index:99999999999">
      <div class="mb-2">
        <v-fab-transition>
            <v-btn
            color="primary"
            v-show="!hidden"
            dark
            fab
            bottom
            right
            href="tel:5513341838"
            x-small
            >
                <v-icon x-small>fa-phone</v-icon>
            </v-btn>
        </v-fab-transition>
      </div>
      <div>
         <v-fab-transition>
          <v-btn
            color="#00B66C"
            dark
            v-show="!hidden"
            fab
            bottom
            right
            href="https://wa.link/i7ip97"
            x-small
            >
                <v-icon x-small>fab fa-whatsapp</v-icon>
            </v-btn>
        </v-fab-transition>
      </div>
        
        
    </div>
    <!-- <v-dialog
      v-model="promoDialog"
      persistent 
      max-width="700px"
      transition="dialog-transition"
    >
      <v-card dark color="background">
        <v-card-title primary-title class="info">
          <div style="font-size:50px">
              Felicidades!!
              <div style="font-size:20px">
                Eres uno de nuestros clientes afortunados.
              </div>
          </div>
          <v-spacer></v-spacer>
          <div class="text-center">
            <v-avatar
              size="100"
              color="white"
            >
              <v-icon x-large white color="info">done</v-icon>
              
            </v-avatar>
            
          </div>
        </v-card-title>

        <v-card-text class="mt-5 text-h6">
          <v-row>
            <v-col cols="12" md="4" >
              <img src="@/assets/images/happy.png" style="max-width:200px" alt="">
            </v-col>
            <v-col cols="12" md="8" >
              Te acabas de ganar:
              <div class="text-h3">
                Un corte de cabello
              </div>
              <div class="text-caption mt-10">
                Muestra el siguiente código QR y haz valido tu premio
              </div>
            </v-col>
            <v-col >

            </v-col>

          </v-row>
            
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" x-large>Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-app>
</template>

<script>
import { mapMutations,mapState } from 'vuex';
import FirstPage from './views/FirstPage.vue';
import Loading from '@/components/Loading.vue';
export default {
  name: 'App',

  components: {
    FirstPage,
    Loading
  },
  watch:{
  },
  data: () => ({
    hidden:true,
    promoDialog:true

  }),
  methods:{
    ...mapMutations(['setHeight','setCookiesAccepted']),
  },
  created(){
    console.log(localStorage.getItem('cookies-accept')=='true',true);
    this.setCookiesAccepted(localStorage.getItem('cookies-accept')=='true');
  },
  mounted(){
    let self=this;
    this.setHeight(window.innerHeight);
    setTimeout(() => {
      self.hidden=false;
    }, 1000);
    const cursor = document.getElementById('cursor');

    document.addEventListener('mousemove', e => {
        cursor.setAttribute("style", "top: " + (e.pageY - 20) + "px; left: " + (e.pageX - 20) + "px;");
    });
  },
  computed:{
    ...mapState(['cookiesAccepted','loading'])
  }
};
</script>
<style >
  *{
    /* font-family: 'Acme', sans-serif; */
    font-family: 'PT Sans Narrow', sans-serif;
  }
  .pt-3.v-sheet.theme--dark.v-toolbar.v-app-bar.v-app-bar--fixed.black{
    box-shadow: 0 3px 3px -1px rgba(255,255,255,.2),0 1px 3px 0 rgba(255,255,255,.14),0 1px 4px 0 rgba(255,255,255,.12)
  }
  .card-rounded.v-sheet.v-card:not(.v-sheet--outlined){
    border-radius: 20px 20px 20px 20px;
    -moz-border-radius: 20px 20px 20px 20px;
    -webkit-border-radius: 20px 20px 20px 20px;

  }
  .image-rounded{
    border-radius: 20px 20px 20px 20px;
    -moz-border-radius: 20px 20px 20px 20px;
    -webkit-border-radius: 20px 20px 20px 20px;
  }
  a:link, a:visited, a:active {
      text-decoration:none;
      font-family: 'PT Sans Narrow', sans-serif;
      color:white
  }
</style>
