import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/es5/locale/es';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { es },
      current: 'es',
    },
  icons: {
    iconfont: 'fa',
  },
    theme:{
      themes: {
        dark: {
          primary: "#8000FF",
          secondary: "#72688A",
          accent: "#281161",
          error: "#E91E63",
          warning: "#ff9800",
          info: "#00afd7",
          success: "#4DB250",
          background:"#191A1A",
          greytext:'#9DA1A5'
        },
        light: {
          primary: "#8000FF",
          secondary: "#72688A",
          accent: "#281161",
          error: "#E91E63",
          warning: "#ff9800",
          info: "#00afd7",
          success: "#4DB250",
          background:"#191A1A",
          greytext:'#9DA1A5'
        }
      },
        
      }
});
