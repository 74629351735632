<template>
    <div >
        <div class="background btn-component" :style="`position:absolute;width:100%;height:${appHeight}px;z-index:9999999999999999`" >
            <v-row justify="center" align="center" style="height:100%">
                <v-col class="text-center" style="position:relative">
                    <div style="color:#1E1F20;font-size:12VW;font-family: 'Koulen', cursive;">
                        LABRUJITA
                    </div>
                    
                </v-col>
            </v-row>
        </div>
        <div :style="`position:absolute;width:100%;height:${appHeight}px;z-index:9999999999999999`" class="btn-component " >
                <v-row justify="center" align="center" style="height:100%">
                    <v-col cols="12" class="text-center " style="color:white;font-size:20px;font-family: 'Koulen', cursive;">
                        <v-row justify="center">
                            <v-col cols="12">
                                <v-btn tile color="white" outlined class="btn-start" x-large @click="moveBtn">Continuar</v-btn>
                            </v-col>
                            <v-col cols="12" sm="10" md="8" lg="6" xl="4">
                                <div class="white--text text-center" style="font-size:15px;">
                                    Esta página utiliza cookies propias y de terceros para mejorar su experiencia. Si continúa con la navegación, consideraremos que acepta este uso.
                                </div>
                            </v-col>
                        </v-row>
                        
                    </v-col>
                </v-row>
        </div>        
    </div>
</template>
<script>
import { mapMutations,mapState } from 'vuex';
export default {
    data:()=>({
        
    }),
    mounted(){

    },
    methods:{
        ...mapMutations(['setCookiesAccepted']),
        moveBtn(e){
            let textContainer=document.getElementsByClassName('btn-component')[0];
            let btnContainer=document.getElementsByClassName('btn-component')[1];
            btnContainer.classList.add("moveup");
            setTimeout(() => {
                textContainer.classList.add("moveup");                
            }, 2000);
            setTimeout(() => {
                localStorage.setItem("cookies-accept",true);
                this.setCookiesAccepted(true)
            }, 4000);
        }
    },
    computed:{
        ...mapState(['appHeight'])
    }
}
</script>
<style scoped>
    .btn-start{

        border:1px solid #ffffff;
        padding:10px;
        padding-left:20px;
        padding-right:20px;
        cursor:cell;
    }
    .btn-start:hover{
        color:#1E1F20 !important;
        background: white;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        -ms-transition: all 1s ease;
    }
    .btn-component{
       -webkit-transition: all 2s ease;
        -moz-transition: all 2s ease;
        -o-transition: all 2s ease;
        -ms-transition: all 2s ease; 
    }
    .btn-component.moveup{
        animation-duration: 8s;
        animation-name: slidein;
        visibility:hidden;
    }
    @keyframes slidein {
        from {
            top: 0%;
        }

        to {
            top: -200%;
        }
    }
</style>