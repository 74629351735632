<template>
    <div>
        <v-overlay
          
          :value="active"
        >   
            <v-progress-circular
            v-if="progress"
            :color="`${color?color:'primary'}`"
            :value="progress"
            :rotate="360"
            :size="dataSize+50"
            :width="15"
            >
            |{{progress}}%
            </v-progress-circular>
            <v-progress-circular
            v-else
            :color="`${color?color:'primary'}`"
            indeterminate
            :size="dataSize"
            :width="5"
            >
            
            </v-progress-circular>
            <br>
            <!-- <div class="text-center" style="width:100%">
                {{progress?'Enviando':text}} 
            </div> -->
            
        </v-overlay>
    </div>
</template>
<script>
export default {
    props:["active","progress","color","size","text"],
    data:()=>({
        dataSize:0
    }),
    created(){
        this.dataSize=this.size||50;
    }
}
</script>